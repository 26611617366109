import { type QuestionAnswerType } from './commonInterfaces'

export const QUESTION_TYPE: Record<
  | 'BOOL'
  | 'DATE'
  | 'DATETIME'
  | 'NO_ANSWER'
  | 'NUMBER'
  | 'REPEATING_QUESTION'
  | 'SECRET'
  | 'SELECT_MANY'
  | 'SELECT_ONE'
  | 'TEXT',
  QuestionAnswerType
> = {
  SELECT_ONE: 'Select One',
  SELECT_MANY: 'Select Many',
  TEXT: 'Text Field',
  BOOL: 'Boolean',
  NUMBER: 'Number',
  DATE: 'Date',
  DATETIME: 'DateTime',
  SECRET: 'Secret',
  NO_ANSWER: 'No Answer',
  REPEATING_QUESTION: 'Repeating Question'
}

export enum TransactionType {
  CaseCost = 'Case Cost',
  ClientDisbursement = 'Client Disbursement',
  Deposit = 'Deposit',
  Expense = 'Expense',
  ExpenseReimbursement = 'Expense Reimbursement',
  FirmDisbursement = 'Firm Disbursement',
  Incoming = 'incoming (legacy)',
  Loan = 'Loan',
  Outgoing = 'outgoing (legacy)',
  SettlementDisbursement = 'Settlement Disbursement'
}
